@use "sass:math";

.registro-step-2 {
    --color-icons: var(--grey);
    --bg: var(--black);
    --color-title: var(--assertive);
    --font-size: var(--font-size-base);
    --font-size-mono: var(--font-size-xsmall);
    --width-form: 50vw;
    --padding-top: 14vw;
    --padding-h: var(--padding-s);
    --padding-bottom: var(--padding-s);
    
    @media (max-width:$smartphone) {
        --padding-top: var(--padding-xl);
        --padding-bottom: var(--padding-xl);
        --font-size: var(--font-size-small);
        --width-form: 100%;
    }  

    &.--submitted {
        --bg: var(--assertive);
        --color-icons: var(--black);
    }
}

.registro-step-2 {
    @include z-index(1);
    background-color: var(--bg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    // position: absolute;
    transition: background .3s ease-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    // @media (max-width:$smartphone) {
    //     justify-content: flex-start;
    //     overflow-y: scroll;
    //     overflow-x: hidden;
    //     -webkit-overflow-scrolling: touch;
    // }

    // @media (min-aspect-ratio: 8/4) {
    //     justify-content: flex-start;
    //     overflow-y: scroll;
    //     overflow-x: hidden;
    //     -webkit-overflow-scrolling: touch;
    // }

    // .__disableScroll & {
    //     overflow: hidden !important;
    //     -webkit-overflow-scrolling: none !important;
    // }

    &[disabled] {
        opacity: 0;
        pointer-events: none;
        height: 0;
        min-height: 0;
        padding: 0;
    }

    .logo-1 {
        position: absolute;
        width: 100%;
        height: calc(var(--padding-top)/2);
        @include z-index(100);
        pointer-events: all !important;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .text {
        @include font-mono();

        font-size: var(--font-size-mono);
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--white);
        padding: 0 var(--padding-xs);
        max-width: rem(780);
        margin-bottom: var(--padding-m);
        
        @media (min-width:$smartphone) {
            margin-bottom: var(--padding-l);
        }
    }

    .logo-1,
    .logo-2,
    .logo-3 {
        position: fixed;
        pointer-events: none;

        a {
            @include basic-a();
            color: var(--grey);
            text-decoration: none;
        }

        .icon {
            position: absolute;
            width: 100%;
            height: auto;
    
            svg {
                width: 100%;
                height: auto;           
                fill: var(--color-icons);
            }
        }
    }

    .logo-1 {
        left: 20vw;
        top: var(--padding-m);
        width: 60vw;

        @media (max-width:$smartphone) {
            left: 5vw;
            top: 5vw;
            width: 90vw;
        }
    }
    
    .logo-2 {
        top: 50vh;
        right: -15vw;
        width: 30vw;
        
        .icon {            
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width:$smartphone) {
            display: none;
        }
    }
    
    .logo-3 {
        top: 50vh;
        left: -15vw;
        width: 30vw;

        .icon {            
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width:$smartphone) {
            display: none;
        }
    }

    .form {
        width: var(--width-form);
    }
}

#CanvasUserFileHolder {
    --button-size: #{math.div(40px, 16px) * 1rem};
    --button-radius: #{math.div(8px, 16px) * 1rem};
    --button-font-size: #{math.div(18px, 16px) * 1rem};
    
    @media (max-width:$smartphone) {
        --button-size: #{math.div(45px, 16px) * 1rem};
        --button-font-size: #{math.div(14px, 16px) * 1rem};
    }

    cursor: grab;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    // pointer-events: none;  
    // user-select: none;

    &.--grabbing {
        cursor: grabbing;
    }

    &[disabled] {
        display: none;
    }

    > canvas {
        position: absolute;
        @include z-index(1);
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-radius: rem(15);
        border: em(1) solid var(--assertive);  
        pointer-events: none;  
    }

    > .controls {
        position: absolute;
        @include z-index(3);
        padding: 4px;
        left: 0;
        top: 0;
        transform: translateY(-100%);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        pointer-events: all;  
    }

    .reset {
        @include z-index(5);
        @include font-mono();

        position: absolute;
        font-size: var(--font-size-xsmall);
        border: 0;
        width: auto;
        height: auto;
        top: rem(10);
        right: rem(10);
        z-index: 5;
        padding: 0;
        border-radius: 0;
        background: transparent;
    }

    > .icon {
        @include z-index(2);
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
    svg {
        @include z-index(2);
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: .6;
        fill: var(--black);
    }

    @media (max-width:$smartphone) {
        > .controls {
            left: auto;
            right: 0;
            transform: translateX(100%);
            width: auto;
            height: 100%;
            flex-direction: column;
        }
    }

    button {
        @include basic-a();
        @include font-sans(1);
        position: relative;
        width: var(--button-size);
        height: var(--button-size);
        background-color: var(--black);
        border-radius: var(--button-radius);
        border: em(1) solid var(--assertive);
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: var(--button-font-size);
        color: var(--assertive);

        .__cursor & {
            &:hover {
                background-color: var(--assertive);
                color: var(--white);

                svg {
                    fill: var(--white);
                }
            }
        }

        svg {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            fill: var(--assertive);
        }
    }
}