@use "sass:math";

.registro-step-3 {
    --color-icons: var(--black);
    --bg: var(--assertive);
    --color-title: var(--assertive);
    --size-logo: #{math.div(100px, 16px) * 1rem};
    --padding: var(--padding-xxl) var(--padding-s) var(--padding-s);
    --max-width-image-holder: #{math.div(200px, 16px) * 1rem};
    --font-size-title: var(--font-size-xlarge);
    --font-size-mono: var(--font-size-base);

    @media (max-width:$smartphone) {
        --font-size-mono: var(--font-size-xsmall);
        --font-size-title: var(--font-size-large);
        --size-logo: #{math.div(100px, 16px) * 1rem};
        --max-width-image-holder: 100%;
    }  
}

.registro-step-3 {
    position: relative;
    z-index: 3;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;

    background-color: var(--bg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    overflow: hidden;
    padding: var(--padding);

    &[disabled] {
        opacity: 0;
        pointer-events: none;
        height: 0;
        min-height: 0;
        padding: 0;
    }

    .logo {
        width: var(--size-logo);
        margin-bottom: var(--padding-s)
    }

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 .15em;
        color: var(--color);
    }

    .text {
        @include font-mono();
        font-size: var(--font-size-mono);
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--white);

        > .hashtag {
            color: var(--assertive);

            .shffl {
                display: inline-block;
                width: auto;
                margin: 0 auto;
            }
            .effect {
                background-color: var(--black);
            }
        }
    }
}

.registro-step-3__gallery {
    margin-top: var(--padding-s);


    display: flex;
    flex-direction: row;
    gap: var(--padding-xs);
    width: 100%;
    justify-content: center;

    @media (max-width:$smartphone) {
        flex-direction: column;
        padding: 0 var(--padding-xxxs);
    }

    .holder-image {
        position: relative;
        max-width: var(--max-width-image-holder);
        flex: 1 0 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-image {
            @include font-mono();
            font-size: var(--font-size-mono);
            text-align: center;
            text-transform: uppercase;
            margin-bottom: .3em;
        }

        @media (max-width:$smartphone) {
            flex: 1 0 auto;
        }

        .canvas-holder {
            position: relative;

            &.--post {
                @include aspect-ratio(1080, 1350);
            }

            &.--story {
                @include aspect-ratio(1080, 1920);
            }

            > canvas,
            > img {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                &:nth-child(2) {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
    }

    a {
        --font-size-big: var(--font-size-xsmall);
        --padding-v: 0;
        --padding-h: var(--font-size-xsmall);
        --color: var(--assertive);
        --bg: var(--black);

       // position: absolute;
        @include font-sans();
        font-size: var(--font-size-xsmall);
        text-transform: uppercase;
        justify-content: center;
        text-align: none;
        text-decoration: none;

        margin-top: var(--padding-xxxs);
        gap: var(--padding-xxs);

        
        width: 100%;
        height: var(--padding-s);
        border-radius: var(--padding-s);
    
        border: var(--stroke) solid var(--black);

        &:after {
            content: "↓"
        }

        .__touch & {
            display: flex;
        }
    }

    .holder {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: var(--padding-xs);
        width: 100%;
        justify-content: center;

        @media (max-width:$smartphone) {
            flex-direction: column;
        }
    
    }

    .canvas-holder {

        > canvas {
            width: 100%;
            height: auto;
            background-color: var(--grey);
            border-radius: var(--radius);
            overflow: hidden;
        }
    }
}

#Instagram__Post {
    height: em(300);
    width: em(300);
}

#Instagram__Story {
    height: em(533);
    width: em(300);
}
