@use "sass:math";

.block-sneakers {
    --font-size-title: var(--font-size-xlarge);
    --font-size-mono: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --max-width: #{math.div(800px, 16px) * 1rem};
    --width-slide: 50vw;
    --height-slide: 0;
    --margin-content: 0;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
        --width-slide: 100vw;
        --height-slide: 100vw;
        --margin-content: -50vw;
    }
}

.block-sneakers {
    position: relative;
    width: 100vw;
    overflow: hidden;

    [data-scroll-sticky] {
        @include z-index(0);
    }

    canvas {
        @include z-index(1);
        position: absolute !important;
        top: 0;
        pointer-events: none;

        @media (max-width: $smartphone) {
            top: auto;
            bottom: 0;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: var(--padding-v) var(--padding-h);
        margin-bottom: var(--margin-content);
    }
    
    .title {
        @include font-sans(.83);
        color: var(--grey);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        max-width: var(--max-width);
    }
    
    .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        max-width: var(--max-width);
        text-transform: uppercase;
    }

    .holder {
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .slide {
        @include font-sans();
        color: transparent;
        font-size: var(--font-size-mono);
        position: relative;
        width: var(--width-slide);
        height: var(--height-slide);

        align-items: center;
        display: flex;
        justify-content: center;

        .target {
            opacity: .3;
            height: 50%;
            width: 100%;
        }
    }
    
    @media (max-width: $smartphone) {
        display: flex;
        flex-direction: column;

        [data-scroll-sticky] {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .content {
            order: 1;
        }

        .slider {
            order: 2;
            margin-top: 15vh;
        }

        .title {
            margin-bottom: var(--padding-s);
        }

        .slide {
            min-width: var(--width-slide);

            &:first-child {
                width: 10vw;
                min-width: 50vw;
            }
            &:last-child {
                width: 30vw;
                min-width: 30vw;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        height: var(--height);
        
        [data-scroll-sticky] {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}