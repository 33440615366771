@use "sass:math";

#Footer {
    --color: var(--primary-color);
    --font-size: var(--font-size-xxsmall);
    --font-size-bottom: var(--font-size-xxxsmall);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --font-size-bottom: var(--font-size-xxsmall);
        --padding-h: var(--padding-xxs);
        --padding-v: var(--padding-xs);
    }
}

#Footer {
    @include font-sans();

    color: var(--color);
    position: relative;
    text-transform: uppercase;
    
    .top,
    .bottom {
        border-top: em(2) solid var(--color);
        padding: var(--padding-v) var(--padding-h);
    }

    .link-logo {
        display: block;
    }

    a {
        @include basic-a();

        color: var(--color);

        svg path {
            fill: var(--color);
        }

        &:not(.link-logo) {  
            white-space: nowrap;
    
            &:hover {
                text-decoration: underline;
            }
        }        
    }

    .top {
        font-size: var(--font-size);

        a {
            .icon {
                fill: var(--color);
                height: 100%;
                width: auto;
            }
        }
    }

    .bottom {
        font-size: var(--font-size-bottom);
    }

    @media (max-width: $smartphone) {
        .link-logo {
            margin: 0;
            text-align: center;
        }
        
        .top {
            div:last-child {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: .8em;
        
                a {
                    margin: var(--padding-s) 0 0;
        
                    .icon {
                        width: 100%;
                    }
                }
            }
        }
        
        .bottom {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: .8em;

            > span {
                flex: 1 0 100%;
                width: 100%;
                text-align: center;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .top,
        .bottom {
            align-items: center;
            display: flex;
            
            a:not(:last-child) {
                margin-right: var(--padding-s);
            }
        }

        .top {
            justify-content: space-between;
        }

        .bottom {
            justify-content: flex-end;
        }
    }
}
