@use "sass:math";

.carrusel {
    --font-size: var(--font-size-large);
    --bg: var(--black);
    --color: var(--assertive);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
    }
}

.carrusel {
    @include font-mono();
    width: 100%;
    overflow: hidden;
    padding: .1em 0 .18em;
    background-color: var(--bg);
    font-size: var(--font-size);
    color: var(--color);

    > .text {
        @include basic-marquee();
    }
}   