@use "sass:math";

.billboard-home {
    --color: var(--primary-color);
    --color-title: var(--assertive);
    --padding: var(--padding-s);
    --font-size: var(--font-size-base);

    @media (max-width:$smartphone) {
        --font-size: var(--font-size-small);
    }  
}

.billboard-home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: var(--padding);
    background-color: var(--black);

    // display: none;

    > h1 {
        position: relative;
        @include font-mono();
        font-size: var(--font-size);
        color: var(--color-title);
        text-align: center;
        text-transform: uppercase;
    }

    > video {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .logo-1, .logo-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .icon {
            position: absolute;
            width: 100%;
            height: auto;
    
            svg {
                width: 100%;
                height: auto;           
                fill: var(--color);
            }
        }
    
        .icon--logo-text-outer {
            top: -5vw;
            width: 100%;
        }
    
        .icon--logo-text-shelf {
            bottom: -9vw;
            width: 100%;
        }
    }
}

.billboard-home__downloads {
    position: absolute;
    bottom: 8vw;
    left: 0;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--padding-xxl);

    @media (max-width:$smartphone) {
        flex-direction: column;
        gap: var(--padding-xxs);
    }
}