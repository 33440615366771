.block-app-download {
    --color-title: var(--assertive);
    --margin: var(--padding-xl);
    --padding: var(--padding-s);
    --font-size: var(--font-size-base);
    
    @media (max-width:$smartphone) {
        --font-size: var(--font-size-small);
        --margin: var(--padding-l);
    }
}

.block-app-download {
    overflow: hidden;

    .icon {
        display: block;
        margin-top: em(-3);
        width: 100%;
        
        svg path {
            fill: var(--grey);
        }
    }

    .text {
        @include font-mono();
        
        color: var(--blue);
        font-size: var(--font-size);
        margin: var(--margin) 0;
        padding: 0 var(--padding);
        text-align: center;
    }
    
    .ctas {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--padding-xxl);
        margin-bottom: var(--margin);

        @media (max-width: $smartphone) {
            flex-direction: column;
            gap: var(--padding-xxs);
        }
    }
}
