#Preloader {
    --font-size: 5vw;
    --color: var(--white);
    --bg: var(--black);
    --logo-size: 10vw;
    --logo-height: 2vw;

    @media (max-width: $smartphone) {
        --logo-size: 40vw;
        --font-size: 16vw;
        --logo-height: 12vw;
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);

    padding-bottom: var(--logo-height);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include font-sans(1);
    
    .logo {
        flex: 0 0 auto;
        width: var(--logo-size);

        svg {
            width: 100%;
            height: auto;
            fill: var(--grey);
        }
    }

    .progress {
        flex: 0 0 auto;
        @include font-mono();
        font-size: var(--font-size);
        margin-top: 0;
        color: var(--assertive);
    }
}
