#Cookies {
    --width: calc(100% - var(--padding-s));
    --height: var(--padding-s);
    --height-btn: calc(var(--padding-s) - var(--padding-xxs) - var(--padding-xxs));
    --color: var(--black);
    --padding: var(--padding-xxs) var(--padding-xs);
    --font-size: var(--font-size-xsmall);
    --bg: var(--assertive);

    &.__success {
        --bg: var(--color-sucess);
        --color: var(--black);
    }

    &.__error {
        --bg: var(--color-error);
        --color: var(--white);
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
}


#Cookies {
    @include z-index($z-index-windows);
    position: fixed;
    width: var(--width);
    right: 0;
    bottom: var(--padding-xxs);
    left: var(--padding-xxs);
    padding: var(--padding);
    border-radius: var(--height);  

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    background-color: var(--bg);
    color: var(--color);

    .content {
        flex: 1 0 50%;
        display: block;
        position: relative;
    }

    .controls {
        flex: 1 0 160px;
        min-width: 160px;
        padding-left: 10px;
        text-align: center;

        display: flex;
        justify-content: flex-end;
    }

    p {
        @include font-sans();
        font-size: var(--font-size);
        margin: 0;
        padding-bottom: 0;

        b, strong {
           
        }

        a {
            @include basic-a();
            text-decoration: underline;
            color: inherit;
        }
    }

    .button {
        --font-size-big: var(--font-size-xsmall);
        --padding-v: 0;
        --color: var(--assertive);
        --bg: var(--black);

        height: var(--height);
        border-radius: var(--height);
        margin-left: .6em;
        border: 1px solid var(--black);
        text-transform: uppercase;

        &:nth-child(2) {
            color: var(--black);
            background: transparent;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
            border-radius: 0;
            font-size: .8em;
            padding-left: .2em;
            padding-right: .2em;
        }
    }

    button:first-of-type {
        border-right: 0;
    }
}

@media (max-width: $smartphone) {
    #Cookies {
        padding: var(--padding-s) var(--padding-xxs);
        border-radius: 1em;  

        .content {
            width: 100%;
            margin-bottom: var(--padding-xxs);
            text-align: center;
        }

        .controls {
            width: 100%;
            min-width: 100%;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p {
            --font-size: var(--font-size-xsmall);
            margin-bottom: 1em;
        }

        .button {
            width: 60%;
            margin: 0;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}
