@use "sass:math";

@keyframes bganimation {
    0%   {  opacity: .7; }
    1%   {  opacity: 1; }
    100%  {  opacity: .7; }
 }

@mixin link-dowload {
    --width: #{math.div(210px, 16px) * 1rem};
    --color: var(--assertive);
    --stroke: var(--assertive);
    --bg: var(--black);
    --color-hover: var(--black);
    --bg-hover: var(--assertive);
    
    @include basic-a();
        
    > .icon {
        width: var(--width);
        height: auto;

        svg {
            width: 100%;
            height: auto;
        }
        .bg { 
            opacity: .7;
            fill: var(--bg);
            transition: fill .5s var(--ease-out-quad);
        }
       
        .text { 
            fill: var(--color);
            transition: fill .5s var(--ease-out-quad);
        }
        .stroke {
            fill: var(--stroke);
        }
    }

    &:hover > .icon {
        --bg: var(--bg-hover);
        --color: var(--color-hover);

        .bg {
            animation: bganimation .8s var(--ease-out-quad);
        }
    }
}

.link-dowload {
    @include link-dowload;
}