:root {
    --white: #ffffff;
    --black: #000;
    --black80: rgba(0, 0, 0, .8);
    --grey: #4D4D4D;
    --blue: #0084FD;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #0084FD;
    --color-focus: #0084FD;
    --color-error: #ff0000;
    // --color-sucess: #4dc247;
}