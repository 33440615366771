#Ranking {
    --font-size-mono: var(--font-size-base);
        
    @media (max-width: $smartphone) {
        --font-size-mono: var(--font-size-small);
    }
}

#Ranking {
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
    
    @include z-index($z-index-windows);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
        @include basic-a();
        background-color: var(--black80);
        padding: 0;

        @include z-index(0);
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    iframe {
        border: solid var(--stroke) var(--assertive);
        border-radius: var(--radius);
        overflow: hidden;

        @include z-index(1);
        position: relative;

        height: 100%;
        width: 100%;

        max-height: 80vh;
        max-width: 90vw;
    }

    .close {
        @include basic-a();
        padding: 0;
        background: transparent;

        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        text-transform: uppercase;
        text-align: center;

        position: absolute;
        right: var(--padding-s);
        top: var(--padding-s);
    }
    
    @media (max-width: $smartphone) {
        .close {
            right: var(--padding-xxs);
            top: var(--padding-xxs);
        }

        iframe {
            max-height: 80vh; /* Fallback for browsers that do not support Custom Properties */
            max-height: calc(var(--vh, 1vh) * 80);
            max-width: 96vw;
        }
    }
}
