@mixin button {
    --color: var(--white);
    --bg: var(--black);
    --padding-v: var(--padding-xxxs);
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-base);
    
    @media (max-width:$smartphone) {
        --padding-h: var(--padding-xs);
        --font-size: var(--font-size-small);
    }  
    
    @include font-mono();
    font-size: var(--font-size);

    align-items: center;
    appearance: none;
    background: var(--bg);
    border: 1px solid var(--assertive);
    border-radius: var(--padding-s);
    cursor: pointer;
    color: var(--color);
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    padding: var(--padding-v) var(--padding-h);
    text-transform: uppercase;
    transition: opacity .1s ease-out;
    position: relative;
    text-align: center;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    &::before,
    &::after {
        color: var(--assertive);
    }
}

.button-register {
    @include button;
}
