@use "sass:math";

.block-text-video {
    --font-size-title: var(--font-size-xlarge);
    --font-size-mono: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --max-width-body: #{math.div(1035px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
    }
}

.block-text-video {
    background-color: var(--black);
    
    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: var(--padding-v) var(--padding-h);
    }
    
    .title {
        @include font-sans(.83);
        color: var(--grey);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        max-width: var(--max-width-title);
        text-align: center;
    }
    
    .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        margin-bottom: var(--padding-l);
        max-width: var(--max-width-body);
        text-transform: uppercase;
        text-align: center;
    }

    .media-holder > img {
        height: 120%;
        object-fit: cover;
        object-position: top;
    }

    @media (max-width: $smartphone) {
        .title , .text {
            margin-bottom: var(--padding-s);
        }
    }
}
