@mixin button {
    --color: var(--primary-bg);
    --bg: var(--primary-color);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-s);
    --font-size-big: var(--font-size-large);

    @include font-sans();

    align-items: center;
    appearance: none;
    background: var(--bg);
    border: 1px solid var(--color);
    cursor: pointer;
    color: var(--color);
    display: flex;
    font-size: var(--font-size-big);
    justify-content: flex-start;
    line-height: 1;
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-align: center;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            color: var(--bg);
            background-color: var(--color);
        }
    }

    &--alt {
        --bg: var(--white);
        --color: var(--black);
    }
}

.button {
    @include button;
}