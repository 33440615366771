@use "sass:math";

.block-basketball-game {
    --font-size-title: var(--font-size-xxxlarge);
    --font-size-mono: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --padding-videos: var(--padding-l);
    --max-width-title: #{math.div(1100px, 16px) * 1rem};
    --max-width-body: #{math.div(1050px, 16px) * 1rem};
    --gap: #{math.div(20px, 16px) * 1rem};
    --icon-size: 40vw;
    --color: var(--assertive);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-large);
        --font-size-mono: var(--font-size-small);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
        --gap: 2px;
        --padding-videos: 4px;
        --icon-size: 60vw;
    }
}

.block-basketball-game {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--black);
    padding: var(--padding-v) var(--padding-h);

    @include font-sans(1);

    .icon--logo-horizontal {
        margin-bottom: var(--padding-s);
        position: relative;
        @include z-index(1);
        
        svg {
            height: auto;
            width: var(--icon-size);
            fill: var(--color);
        }
    }

    .icon--basket-bg {
        @include z-index(0);

        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        
        svg {
            height: 100%;
            width: 100%;

            object-fit: cover;

            fill: #4d4d4d;
        }
    }

    .title {
        @include font-sans(.83);
        color: var(--grey);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-xxs);
        text-align: center;
        max-width: var(--max-width-title);

        -webkit-text-stroke: var(--stroke) var(--assertive);
        color: transparent;
        font-weight: 400;

        position: relative;
    }
    
    .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        margin-bottom: var(--padding-l);
        max-width: var(--max-width-body);
        text-transform: uppercase;
        text-align: center;
    } 
    
    .target {
        background-color: orange;
        opacity: 0;
        height: 26vw;
        width: 26vw;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    .button-register {
        margin-top: var(--padding-m);
    }

    &__video {
        border-radius: var(--radius);
        overflow: hidden;
        width: 100%;

        video {
            border-radius: var(--radius);
        }
    }

    @media (max-width: $smartphone) {
        .icon--logo-horizontal {
            margin-bottom: var(--padding-xs);
        }

        .button-register {
            margin-top: var(--padding-s);
        }

        .target {
            height: 36vw;
            width: 36vw;
        }
    }
}
