@use "sass:math";

.block-character-showcase {
    --color: var(--black);
    --bg: var(--grey);
    --padding: var(--padding-s);
    --font-size: var(--font-size-xlarge);
    --icon-size: #{math.div(120px, 16px) * 1rem};

    @media (max-width: $smartphone) {
        --icon-size: #{math.div(60px, 16px) * 1rem};
        --font-size: var(--font-size-base);
    }
}

.block-character-showcase {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--padding) 0;
    background-color: var(--bg);

    // display: none;

    .icon--icon-outershelf {
        width: var(--icon-size);
        height: var(--icon-size);
        margin-bottom: var(--padding-m);

        svg {
            fill: var(--color);
        }
    }

    > .text {
        position: relative;
        @include font-sans();
        font-size: var(--font-size);
        color: var(--color);
        text-align: center;
        margin-bottom: var(--padding-l);
        padding: 0 var(--padding);
        
        strong {
            -webkit-text-stroke: var(--stroke) var(--assertive);
            color: transparent;
            font-weight: 400;
        }
    }
}
