@use "sass:math";

.registro-step-1 {
    --color: var(--grey);
    --bg: var(--black);
    --color-title: var(--assertive);
    --padding: var(--padding-s);
    --font-size: var(--font-size-base);

    @media (max-width:$smartphone) {
        --font-size: var(--font-size-small);
    }  
}

.registro-step-1 {
    position: absolute;
    @include z-index(11);
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-color: var(--bg);
    
    &[disabled] {
        opacity: 0;
        pointer-events: none;
        height: 0;
        min-height: 0;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        padding: var(--padding);
    }    

    h1 {
        position: relative;
        @include font-mono();
        font-size: var(--font-size);
        color: var(--color-title);
        text-align: center;
        text-transform: uppercase;    
    }
    
    .logo-1,
    .logo-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .icon {
            position: absolute;
            width: 100%;
            height: auto;
    
            svg {
                width: 100%;
                height: auto;           
                fill: var(--color);
            }
        }
    
        .icon--logo-text-outer {
            top: -5vw;
            width: 100%;
        }
    
        .icon--logo-text-shelf {
            bottom: -9vw;
            width: 100%;
        }
    }

    .button-register {
        margin-top: var(--padding-l);
    }
}
