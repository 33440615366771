@use "sass:math";

.block-contest {
    --font-size-title: var(--font-size-xlarge);
    --font-size-text-legal: var(--font-size-xsmall);
    --font-size-mono: var(--font-size-base);
    --font-size-mono-bottom: var(--font-size-base);
    --font-size-prize-title: var(--font-size-large);
    --font-size-prize-text: var(--font-size-xsmall);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --max-width-body: #{math.div(1035px, 16px) * 1rem};

    --padding-holder-prizes: var(--padding-m);
    --padding-prizes: 0 0 var(--padding-s);

    --height-deco: 50vw;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-prize-title: var(--font-size-base);
        --font-size-text-legal: var(--font-size-xxsmall);
        --font-size-prize-text: var(--font-size-xxsmall);
        --font-size-mono-bottom: var(--font-size-xsmall);
        --height-deco: 100vw;
        
    }
}

.block-contest {
    background-color: var(--black);
    
    .top {
        padding: var(--padding-v) var(--padding-h);

        @media (max-width: $smartphone) {
            padding: var(--padding-v) var(--padding-h) 0;
        }

        > * {
            margin: 0 auto;
        }
    }
    
    .top > .title {
        @include font-sans(.83);
        color: var(--grey);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        max-width: var(--max-width-title);
        text-align: center;

        strong {
            -webkit-text-stroke: var(--stroke) var(--assertive);
            color: transparent;
            font-weight: 400;
        }
    }
    
    .top > .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        margin-bottom: var(--padding-m);
        max-width: var(--max-width-body);
        text-transform: uppercase;
        text-align: center;

        strong {
            color: var(--assertive);
            font-weight: 400;
        }

        p {
            margin: 1em 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .text-legal {
        @include font-sans(1.4);
        color: var(--grey);
        font-size: var(--font-size-text-legal);
        margin-bottom: var(--padding-l);
        max-width: var(--max-width-body);
        text-align: center;
        margin-bottom: var(--padding-m);

        a {
            @include basic-a();
            color: var(--assertive);
            text-decoration: underline;
        }
    }

    .media-holder > img {
        height: 120%;
        object-fit: cover;
        object-position: top;
    }

    @media (max-width: $smartphone) {
        .title , .text {
            margin-bottom: var(--padding-s);
        }
    }
}

.block-contest__prizes {
    position: relative;
    @include z-index(3);
    --color: #ff0000;
    display: flex;
    gap: var(--padding-xxs);
    padding: 0 var(--padding-holder-prizes);

    .gold {
        order: 2;
        --color: #fbee3a;
    }

    .silver {
        order: 1;
        --color: #fafafa;
    }

    .bronze {
        order: 3;
        --color: #a26c08;
    }

    > .item {
        position: relative;
        flex: 1;
        flex-shrink: 0;
        color: var(--color);
        text-align: center;
        padding: var(--padding-prizes);
        overflow: hidden;
        background-color: var(--black);
        border-radius: var(--radius);

        &:after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            border-radius: var(--radius);
            border: 1px solid var(--color);
        }

        > figure {
            position: relative;
            width: 100%;
            border-radius: var(--radius);
            overflow: visible;
        }

        > .title {
            position: relative;
            @include font-sans(.83);
            color: var(--color);
            font-size: var(--font-size-prize-title);
            margin-bottom: .1em;
            padding: 0 var(--padding-m);
        }

        > .text {
            position: relative;
            @include font-sans(1.3);
            color: var(--color);
            font-size: var(--font-size-prize-text);
            margin-bottom: .4em;
            padding: 0 var(--padding-m);
        }
    }

    @media (max-width: $smartphone) {
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        scroll-snap-type: x mandatory;
        gap: var(--padding-xxs);
        padding: 0 var(--padding-xxs) 4rem;
        
        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        > .item {
            scroll-snap-align: center;
            flex: 1 0 80vw;
        }

        .gold {
            order: 1;
        }
    
        .silver {
            order: 2;
            transform: translateY(2rem);
        }
    
        .bronze {
            order: 3;
            transform: translateY(4rem);
        }
    }
}



.block-contest .carrusel {
    position: relative;
    margin: var(--padding-l) 0 0;
}

.block-contest__middle,
.block-contest__bottom {
    position: relative;
    padding: var(--padding-v) var(--padding-h);

    /*&:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 50vw;
        top: -10px;
        left: 0;
        background: rgb(0,132,253);
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }*/

    @media (max-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h);
    }

    > * {
        margin: 0 auto;
    }

    .link-holder {
        display: flex;
        justify-content: center;
        align-items: center;

        > .button-register {
            display:inline-flex;
            @include basic-a();
            margin: 0 auto;
        }
    }
    

    > .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono-bottom);
        margin: 0 auto var(--padding-m);
        max-width: var(--max-width-body);
        text-transform: uppercase;
        text-align: center;

        > a {
            @include basic-a();
            text-decoration: none;
            display: inline-block;
            border-bottom: 1px solid var(--white);
            margin-left: .5em;
        }

        strong {
            color: var(--assertive);
            font-weight: 400;
        }

        p {
            margin: 1em 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            position: relative;
            counter-reset: section;
            list-style: none;

            li {
                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                counter-increment: section;
                text-align: left;

                @media (max-width: $smartphone) {
                    display: block;
                    text-align: center;
                }

                @media (min-width: $smartphone) {
                    min-height: rem(90);
                    padding-left: 30%;
                    position: relative;

                    &::before {
                        left: 0;
                        position: absolute;
                        top: 0;
                    }
                }

                &::before {
                    content: "0"counter(section);
                    @include font-sans(1);
                    font-size: var(--font-size-title);
                    color: transparent;
                    flex: 0 0 30%;
                    width: 30%;
                    text-align: center;
                    -webkit-text-stroke: var(--stroke) var(--assertive);
                    margin-bottom: -.25em;

                    @media (max-width: $smartphone) {
                        display: block;
                        text-align: center;
                        width: 100%;
                        margin-bottom: .2em;
                    }
                }

                margin-bottom: 2em;

                a {
                    @include basic-a();
                    text-decoration: none;
                    word-break: break-all;
                    display: inline;
                    color: var(--white);
                    font-weight: 600;
                    background-color: var(--assertive);
                    margin-left: .32em;
                }
                
            }
        }
    }
}