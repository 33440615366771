.registro {
    background-color: var(--black);
    min-height: 100vh;
}

.registro + #Footer {
    opacity: 0;
    background-color: var(--assertive);
    @include z-index(1);
}

#CanvasHolder {
    position: fixed;
    @include z-index(100);
    top: 10px;
    left: 10px;
    width: 40vw;
    

    > div {
        @include aspect-ratio(1080, 1920);
        background-color: aqua;
    }

    canvas {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }

    #Instagram__Post__Image {
        top: 200%;
        width: 100%;
        height: auto;
        opacity: 0;
    }
}