@use "sass:math";

.block-acordions {
    --font-size-title: var(--font-size-xlarge);
    --font-size-mono: var(--font-size-base);
    --font-size-text: var(--font-size-small);
    --font-size-number: var(--font-size-xsmall);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --max-width-body: #{math.div(620px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-text: var(--font-size-xsmall);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
    }
}

.block-acordions {
    background-color: var(--grey);
    padding: var(--padding-v) var(--padding-h);
    
    @include font-sans(1);
    
    .title {
        @include font-sans(.83);
        color: var(--black);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        text-transform: uppercase;
    }
    
    .text {
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        margin-bottom: var(--padding-m);
        max-width: rem(650);
        text-transform: uppercase;
    }
    
    &__ul {
        list-style: none;
        margin: 0 auto;
        
        > li {
            border-bottom: em(2) solid var(--blue);
        }
        
        //ACORDEON
        article {
            --duration: 0.4s;
            --ease: var(--ease-in-out-quad);
            
            color: var(--black);
            font-size: var(--font-size-text);

            a {
                @include basic-a();
                color: var(--assertive);
                text-decoration: underline;
            }

            li::before {
                content: '·';
                margin-right: rem(15);
            }

            > div {
                ol,
                p {
                    margin: .5em 0 1em;

                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                strong {
                    text-transform: uppercase;
                }

                ol {
                    padding-left: 1em;
                    list-style: decimal;
                }

                li::before {
                    display: none;
                }
            }
        }
    
        article > button {
            --rot: 0deg;
            --strokeCross: #{math.div(2px, 16px) * 1rem};
            
            @include basic-a();
            background-color: transparent;
            position: relative;
            width: 100%;
            padding: var(--padding-xs) var(--padding-m) var(--padding-xxs) 0;
            line-height: 1;
            text-align: left;
            text-transform: uppercase;

            @media (max-width: $smartphone) {
                padding-left: var(--padding-s);
                position: relative;
                
                > sup {
                    left: 0;
                    position: absolute;
                    top: var(--padding-s);
                }
            }

            > sup {
                font-size: var(--font-size-number);
            }
    
            > span {
                position: absolute;
                right: 0.1em;
                top: 50%;
                width: var(--padding-s);
                height: var(--padding-s);
    
                transform-origin: 50% 50%;
                transform: translate3d(0, -50%, 0) rotate(var(--rot));
    
                transition: transform 0.4s var(--ease);
    
                &::after,
                &::before {
                    content: '';
                    position: absolute;
                }
    
                &::after {
                    top: 0;
                    left: 50%;
                    width: var(--strokeCross);
                    height: 100%;
                    transform: translateX(-50%);
                    background: var(--blue);
                }
    
                &::before {
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: var(--strokeCross);
                    transform: translateY(-50%);
                    background: var(--blue);
                }
            }
        }
    
        article[aria-expanded='false'] {
            visibility: visible;
            pointer-events: initial;
        }
        
        article[aria-expanded='true'] > button {
            --rot: 135deg;
        }
    
        article > div {
            max-width: var(--max-width-body);
            padding: var(--padding-xxs) 0 var(--padding-s);
        }
    }

    &__bottom {
        position: relative;
        padding: var(--padding-v) var(--padding-h) 0;

        .link-holder {
            display: flex;
            justify-content: center;
            align-items: center;

            > .button-register {
                display:inline-flex;
                @include basic-a();
                margin: 0 auto;
            }
        }
    }
    
    @media (max-width: $smartphone) {
        .title , .text {
            margin-bottom: var(--padding-s);
        }

        &__bottom {
            padding: var(--padding-v) 0 0;
        }
    }
}
