@use "sass:math";

.block-streetwear {
    --font-size-title: var(--font-size-xlarge);
    --bg: var(--assertive);
    --color: var(--black);



    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --padding-videos: var(--padding-l);
    --max-width-title: #{math.div(1100px, 16px) * 1rem};
    --max-width-body: #{math.div(650px, 16px) * 1rem};
    --gap: #{math.div(20px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
        --gap: 2px;
        --padding-videos: 4px;
    }
}

.block-streetwear {
    display: flex;
    background-color: var(--bg);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    
    padding: var(--padding-v) 0;

    @include font-sans(1);

    .content {
        @include aspect-ratio(1920,1586);

        > img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
        }
    }
    
    .title {
        position: absolute;
        top: 25%;
        left: 0;
        @include font-sans();
        color: var(--color);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        text-align: center;
        padding: 0 calc((100% - var(--max-width-title))*.5);
    } 

    .carrusel > .text {
        --animation: 20s;
        text-transform: uppercase;
    }
}
