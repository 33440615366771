@use "sass:math";

.block-video {
    --color: var(--primary-color);
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-xlarge);
    --icon-size: #{math.div(120px, 16px) * 1rem};
    --margin-top: var(--padding-l);
    --margin-bottom: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --margin-bottom: 0;
    }
}

.block-video {
    margin: var(--margin-top) 0 var(--margin-bottom);
   
    .video-holder {
        padding: 0 var(--padding-h);
    }

    .media-holder {
        border-radius: var(--radius);
    }

    video {
        object-fit: cover;
        object-position: center;
    }
    
    .icon {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        margin-top: -4vw;

        svg {
            width: 100%;
            height: auto;
            fill: var(--color);
        }
    }
}
