.form {
    --font-size: var(--font-size-small);
    --font-size-label: var(--font-size-xsmall);
    --margin-fildset: var(--padding-l);
    --margin-label: var(--padding-xxs);
    --width-file: #{rem(280)};
    --height-file: calc(var(--width-file) * 1.5);
    
    @media (max-width:$smartphone) {
        --font-size: var(--font-size-xsmall);
        --font-size-label: var(--font-size-xxsmall);
        --margin-fildset: var(--padding-xs);
        --margin-label: var(--padding-xxxs);
        --width-file: #{rem(220)};
    }  
}

.form {
    font-size: var(--font-size);
    margin-left: auto;
    margin-right: auto;

    input,
    label,
    textarea,
    select,
    fieldset {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    textarea {
        height: 100%;
        resize: none;
        width: 100%;
    }

    select,
    input {
        @include font-sans();

        background: transparent;
        color: var(--white);
        cursor: pointer;
        text-transform: uppercase;

        &:not([type='checkbox']) {
            height: var(--input-height);
            line-height: var(--input-height);
            width: 100%;
        }
    }

    label {
        @include font-mono();

        color: var(--white);
        display: block;
        font-size: var(--font-size-label);
        text-transform: uppercase;
        margin-bottom: var(--margin-label);
    }

    &__wrapper:not(:last-child) {
        margin-bottom: var(--padding-s);
    }

    &__wrapper:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    fieldset {
        border-bottom: em(1) solid var(--white);
        padding: 0 0 var(--padding-xxxxs);
        position: relative;
        transition: border-color .3s ease-out;

        &:not(:last-child) {
            margin-bottom: var(--margin-fildset);
        }
        
        &.--textarea {
            height: calc(rem(12) + 6 * var(--input-height));

            label {
                top: var(--input-height) / 2;
            }
        }

        &.--select {
            &::before {
                color: currentColor;
                content: '+';
                height: 100%;
                line-height: 180%;
                position: absolute;
                pointer-events: none;
                right: 0;
                top: 0;
                transform: scale(1.5);
            }
        }

        &.--file,
        &.--checkbox {
            border: 0;

            &.--focus,
            &.--success,
            &.--error {
                label {
                    transform: none;
                    top: 0;
                }
            }
            
            &.--error label {
                border-color: var(--color-error);
            }

            label {
                cursor: pointer;
                pointer-events: initial;
                position: relative;
                transform: none;
                top: 0;
            }
        }

        &.--file {
            position: relative;
            margin-bottom: var(--padding-xxs);
            width: var(--width-file);
            padding: 0;
            height: var(--height-file);

            &:before {
                content: '';
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                border-radius: rem(15);
                border: em(1) solid var(--assertive);
                pointer-events: none;
            }

            &.--error:before {
                border-color: var(--color-error);
            }

            label {
                border-radius: rem(15);
                text-align: center;
                margin-bottom: 0;
                width: var(--width-file);
                height: var(--height-file);
                
            }

            .imageFile {
                position: absolute;
                border-radius: rem(15);
                bottom:-5%;
                left:0;
                width: 100%;
                height: 100%;
                opacity: 0;
               
                object-fit: cover;
                object-position: 24% center;
                pointer-events: none;

                &[src=""] {
                    display: none;
                }
            }

            svg {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                fill: var(--grey);
                opacity: .4;
            }

            .content {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: var(--padding-xs);
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000000, $alpha: .4);
            }

            input {
                display: none;
            }

            .lbl {
                margin-bottom: var(--padding-xxs);
            }

            .btn {
                @include font-sans();
                color: var(--white);
                font-size: var(--font-size-label);
                text-transform: uppercase;
                margin-bottom: var(--padding-xxxs);
            } 

            // .remove {
            //     background-color: transparent;
            //     height: rem(16);
            //     margin-left: var(--padding-xxs);
            //     padding: 0;
            //     width: rem(16);
                
            //     svg {
            //         height: 100%;
            //         width: 100%;
            //         transform: rotate(45deg);
            //     }
            // }
        }

        &.--checkbox {
            margin: 0;
            padding: 0;
        }

        &.--focus {
            border-color: var(--color-focus);
        }

        &.--success {
            border-color: var(--color-focus);
        }

        &.--error {
            border-color: var(--color-error);
        }
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: var(--grey);
    }

    .hidden {
        display: none;
    }

    .button {
        --padding-h: var(--padding-xl);

        &[disabled] {
            opacity: .5;
            pointer-events: none;
        }

        
    }

    .button-register {
        min-width: var(--width-file);
        margin-bottom: var(--padding-xxs);

        > div {
            margin: 0 auto;
        }
    }

    // @media (max-width:$smartphone) {

    // } 

    @media (min-width:$smartphone) {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        > *:first-child {
            margin-right: var(--padding-m);
            flex-grow: 1;
        }

        > *:last-child {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    } 
}
