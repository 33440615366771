.widget-registro {
    --bg: var(--black);
}

.widget-form {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--bg);
    padding: var(--padding-v) var(--padding-h);
}
