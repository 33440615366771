// html {
//     width: 100%;
//     height: 100%;
// }

// body {
//     width: 100vw;
//     min-height: 100%;
//     color: var(--primary-color);
//     background: var(--primary-bg);
    
//     main {
//         width: 100%;
//     }
    

//     &:not(.__scroll-manual) {
//         overflow: hidden;
//         height: 100vh;

//         .wrap {
//             position: fixed;
//             top: 0;
//             right: 0;
//             bottom: 0;
//             left: 0;
//             width: 100vw;
//         }

//         // main {
//         //     @include z-index($z-index-wrap);
//         //     position: fixed;
//         //     height: 100%;
//         //     top: 0;
//         //     left: 0;
//         // }
//     }

//     &.__scroll-manual {
//         overflow-y: scroll;
//         overflow-x: hidden;
//         -webkit-overflow-scrolling: touch;
//     }

//     &.__touch {
//         overflow: hidden;

//         #Main {
//             .wrap {
//                 height: 100%;
//                 overflow-y: scroll;
//                 overflow-x: hidden;
//                 -webkit-overflow-scrolling: touch;
//             }

//             @media (min-width: $smartphone) {
//                 article {
//                     min-height: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//     }

//     &.__disableScroll {
//         overflow: hidden !important;
//         -webkit-overflow-scrolling: none !important;
        
//         #Main {
//             .wrap {
//                 overflow: hidden !important;
//                 -webkit-overflow-scrolling: none !important;
//             }
//         }
//     }
// }

// .icon {
//     display: inline-block;

//     svg {
//         height: 100%;
//         width: 100%;
//     }
// }

// .line-parent {
//     overflow: hidden;
// }

// *[aria-current="page"] {
//     pointer-events: none;
//     text-decoration: none;
// }

// button[disabled] {
//     pointer-events: none;
//     opacity: .6;
// }

// [hidden] {
//     visibility: hidden;
//     pointer-events: none;
// }

// // Tweakpane
// .tp-dfwv {
//     @include z-index($z-index-tweakpane);
// }

// .shffl {
//     position: relative;
//     .main {
//         opacity: 0;
//     }

//     .effect {
//         position: absolute;
//         top:0;
//         left:0;
//         width: 100%;
//         height: 100%;
//     }
// }

// [data-shffl] {
//     > div {
//         min-height: 1em;
//     }
// }

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }

        // main {
        //     @include z-index($z-index-wrap);
        //     position: fixed;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        // }
    }
    
    &.__scroll-manual {
        user-select: none;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &.__disableScroll {
        height: 100vh;
        overflow: hidden !important;
        -webkit-overflow-scrolling: none !important;
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .6;
}

[hidden] {
    visibility: hidden;
    pointer-events: none;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

.shffl {
    position: relative;
    .main {
        opacity: 0;
    }

    .effect {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
}

[data-shffl] {
    > div {
        min-height: 1em;
    }
}