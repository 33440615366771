@use "sass:math";

#Header {
    --width: calc(100% - var(--padding-s));
    --width-lang: #{math.div(90px, 16px) * 1rem};
    --height: var(--padding-s);
    --color: var(--black);
    --padding: var(--padding-xxs);
    --font-size: var(--font-size-xsmall);
    --bg: var(--assertive);

    .icon--logo-horizontal {display: block};
    .icon--logo-square {display: none};

    @media (max-width: $smartphone) {
        --padding: var(--padding-xxs);
        --height: var(--padding-s);

        .icon--logo-horizontal {display: none};
        .icon--logo-square {display: block};

        .header__content .title {
            display: none;
        }
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    right: 0;
    top: var(--padding-xxs);
    left: var(--padding-xxs);
    padding: var(--padding);
    border-radius: var(--height);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);

    .logo {
        @include basic-a();
        height: var(--height);
        
        .icon {
            height: 100%;
            width: auto;
        }

        svg {
            height: 100%;
            width: auto;
            fill: var(--color);
        }
    }

    a {
        @include basic-a();
    }
}

.header__content {
    display: flex;
    align-items: center;

    .title {
        @include font-sans(1);
        color: var(--color);;
        font-size: var(--font-size);
        margin-right: var(--padding-xxxs);
        text-transform: uppercase;
    }

    .link-app {
        width: var(--height);
        height: var(--height);
        border-radius: 50%;
        background-color: var(--color);
        color: var(--assertive);
        padding: .2rem;
        margin-right: var(--padding-xxxs);

        svg {
            fill: var(--color);
        }
    }
}

.header__content__langs {
    --height-over: calc(var(--height) * 2);
    --height-normal: var(--height);
    --height-active: var(--height-normal);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width-lang);
    height: var(--height);
   
    cursor: pointer;
    

    .holder {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: var(--height-active);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        background-color: var(--color);
        border-radius:  calc(var(--height) * .5);

        transition: height .4s var(--ease-in-out-cubic);

        &:after {
            content: '↓';
            @include font-sans();
            position: absolute;
            height: var(--height);
            bottom: 0;
            right: var(--padding-xxs);
            color: var(--assertive);
            font-size: var(--font-size);
            line-height: var(--height);
        }
    }

    &:hover {
        --height-active: var(--height-over);
    }

    a {
        @include basic-a();
        @include font-sans();
        width: 100%;
        font-size: var(--font-size);
        color: var(--assertive);
        height: var(--height);
        line-height: var(--height);
        padding: 0 var(--padding-xxs);

        &.is-active {
            order: 1;
            pointer-events: none;
        }

        &:not(.is-active) {
            order: 2;
        }
    }
}