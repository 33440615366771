.custom-checkbox {
    --border-color: var(--white);
    --font-size: var(--font-size-xxsmall);

    &.--success {
        --border-color: var(--white);
    }
    
    &.--error {
        --border-color: var(--color-error);
    }

    input {
        display: none;
    }

    label {
        @include font-sans(1.5);
        color: var(--white);
        display: flex;
        font-size: var(--font-size);
        text-transform: initial;

        a {
            @include basic-a();
            color: inherit;
            text-decoration: underline;
        }
    }

    .checkmark {
        border: em(2) solid var(--border-color);
        border-radius: 50%;
        content: '';
        cursor: pointer;
        display: block;
        height: rem(15);
        margin-right: var(--padding-xxxs);
        min-width: rem(15);
        position: relative;
        width: rem(15);

        > span {
            display: none;
            position: absolute;
            pointer-events: none;
            background: var(--white);
            width: 80%;
            height: 80%;
            border-radius: 50%;
            transform: none;
            left: 10%;
            top: 10%;
        }
    }

    input:checked + label .checkmark > span {
        display: block;
    }
}
