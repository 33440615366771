@use "sass:math";

.block-multi-video {
    --font-size-title: var(--font-size-xlarge);
    --font-size-mono: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --padding-videos: var(--padding-l);
    --max-width-title: #{math.div(1100px, 16px) * 1rem};
    --max-width-body: #{math.div(650px, 16px) * 1rem};
    --gap: #{math.div(20px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xxs);
        --font-size-title: var(--font-size-base);
        --font-size-mono: var(--font-size-small);
        --font-size-number: var(--font-size-xxsmall);
        --padding-accordions: 0;
        --gap: 2px;
        --padding-videos: 4px;
    }
}

.block-multi-video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--black);
    padding: var(--padding-v) var(--padding-h);

    // display: none;

    @include font-sans(1);

    .title {
        order: 1;
        @include font-sans(.83);
        color: var(--grey);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
        text-align: center;
        max-width: var(--max-width-title);
    }
    
    .text {
        order: 3;
        @include font-mono();
        color: var(--white);
        font-size: var(--font-size-mono);
        margin-top: var(--padding-l);
        max-width: var(--max-width-body);
        text-transform: uppercase;
        text-align: center;
    }
    
    .videos {
        width: 100%;
        height: 52vw;
        order: 2;
        padding: 0 var(--padding-videos);
        display: grid;
        gap: var(--gap);
        grid-template-areas:
            "a a a a b b b b b"
            "a a a a b b b b b"
            "a a a a b b b b b"
            "a a a a b b b b b"
            "c c c c b b b b b"
            "c c c c b b b b b";

        > div {
            
            position: relative;
            &:nth-child(1) { grid-area: a; }
            &:nth-child(2) { grid-area: b; }
            &:nth-child(3) { grid-area: c; }
        }    

        video {
            position: absolute;
            top:0;
            left:0;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            border: solid var(--stroke) var(--assertive);
            border-radius: var(--radius);
        }
    }    

    @media (max-width: $smartphone) {
        .title {
            margin-bottom: var(--padding-s);
        }

        .text {
            margin-top: var(--padding-s);
        }
    }
}
