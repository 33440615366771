@use "sass:math";

:root {
    --font-sans: 'FKGrotesk-Medium', sans-serif;
    --font-sans-bold: 'FKGrotesk-Medium', sans-serif;
    --font-mono: 'Meyrin', monospace;
    
    --font-size-xxxlarge: #{math.div(240px, 16px) * 1rem};    
    // --font-size-xlarge: #{math.div(120px, 16px) * 1rem};    
    --font-size-xlarge: #{math.div(120px, 16px) * 1rem};    
    --font-size-large: #{math.div(80px, 16px) * 1rem};
    --font-size-base: #{math.div(36px, 16px) * 1rem};
    --font-size-small: #{math.div(30px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(20px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(12px, 16px) * 1rem};

    --line-height-sans: .83;
    --line-height-mono: 1;
    --letter-spacing-sans: -0.03em;

    --min-font-size: 5px;
    --max-font-size: 89px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        font-size: clamp(12px, 4.2vw, 16px);
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    letter-spacing: var(--letter-spacing-sans);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-mono($line-height:0) {
    font-family: var(--font-mono);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-mono);  }

    @media (max-width: $smartphone) {
        font-weight: 600;
    }
}